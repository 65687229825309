<template>
  <div class="w-full flex flex-col">
    <div v-if="loading" class="spinner">
      <a-spin>
        <a-icon slot="indicator" type="loading" style="font-size: 64px" spin />
      </a-spin>
    </div>
    <div
      class="w-11/12 mb-4 hover:bg-gray-200 rounded-xl shadow-md bg-gray-100"
      v-for="(complaint, idx) in complaints"
      :key="idx"
    >
      <div
        class="px-6 py-2 rounded-lg flex justify-between items-center cursor-pointer"
        @click="toggleAccordion('item' + idx)"
        style="border-bottom: gray solid 1px"
      >
        <div class="flex">
          <p
            class="h-3 w-3 mr-4 my-auto rounded-full"
            :class="
              complaint.estado_duplicidad === 'Es queja principal'
                ? 'bg-blue-700'
                : 'bg-red-700'
            "
          ></p>
          <p class="text-dark text-left">
            <b>No.de Radicado {{ complaint.codigo_queja }} </b>
          </p>
        </div>
        <span
          class="h-5 w-5 m-2 flex justify-center items-center transition"
          :class="{
            'transform rotate-180 border-bottom-0':
              accordionOpened == 'item' + idx,
          }"
        >
          <a-icon type="caret-down"></a-icon>
        </span>
      </div>
      <div
        :ref="'item' + idx"
        hidden
        class="w-full flex flex-col bg-white pt-3 pl-2 pr-10 lg:flex-col lg:items-start rounded-b-xl"
      >
        <GeneralManagement :complaint="complaint" :customer="customer" />
        <AnnexesManagement :complaint="complaint" :references="references" />
        <AnswerManagement :complaint="complaint" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { format } from "date-fns";
import GeneralManagement from "@/components/Dashboard/Complaints/ComplaintManagement/GeneralManagement";
import AnnexesManagement from "@/components/Dashboard/Complaints/ComplaintManagement/AnnexesManagement";
import AnswerManagement from "@/components/Dashboard/Complaints/ComplaintManagement/AnswerManagement";
import ComplaintStatusLabel from "../Complaints/ComplaintStatusLabel";

export default {
  components: {
    GeneralManagement,
    AnnexesManagement,
    AnswerManagement,
    ComplaintStatusLabel,
  },
  props: {
    user: { type: Object, require: true },
  },
  data() {
    return {
      userBasicInfo: "",
      customer: null,
      showModal: false,
      loading: false,
      complaint_code: null,
      errorCustomer: false,
      feedback: "",
      accordionOpened: "",
      complaints: [],
      references: "!,2,6,7", // !:not in. 2:attorney. 6:management. 7:answer
    };
  },
  created() {
    this.userBasicInfo = this.user;
    this.loading = true;
    this.getUserData();
    this.getDuplicateComplaintsInfo();
  },
  computed: {
    ...mapState({
      formOptions: (state) => {
        return state.form;
      },
    }),
  },
  methods: {
    async getDuplicateComplaintsInfo() {
      let query = `${this.userBasicInfo.group_id}/?tipo_identificacion_CF=${this.userBasicInfo.document_type}&nombre_entidad=${this.userBasicInfo.entity_name}&numero_id_CF=${this.userBasicInfo.document_number}`;
      let { data, error } = await this.$api.getDuplicateComplaintsByUser(query);
      if (data) {
        data.forEach((complaint_id) => {
          this.getComplaintDetail(complaint_id.codigo_queja);
        });
      } else if (error) {
        this.showToast(
          "error",
          "No se pudo obtener el detalle de quejas duplicadas. "
        );
        console.error(error);
      }
    },
    async getComplaintDetail(complaint_code) {
      this.loading = true;
      let { error, data } = await this.$api.getDetailComplaint(complaint_code);
      if (error)
        this.showToast("error", "Error recuperando datos de la queja.");
      else if (data) {
        const complaint = data;
        if (complaint.codigo_queja_principal)
          complaint.estado_duplicidad = complaint.codigo_queja_principal =
            "Unificada en " + complaint.codigo_queja_principal;
        else if (complaint.estado_duplicidad === "Principal")
          complaint.estado_duplicidad = "Es queja principal";
        else complaint.estado_duplicidad = "Duplicada";
        this.complaints.push({
          ...complaint,
          duplicity: complaint.estado_duplicidad,
          lgbtiq:
            complaint.lgbtiq == 1 ? "Sí" : complaint.lgbtiq == 2 ? "No" : "",
          specialCondition:
            complaint.condicion_especial === "No aplica"
              ? 2
              : complaint.condicion_especial == null
              ? null
              : 1,
          es_apoderado: complaint.apoderado_nombre ? 2 : 1,
          abroad: complaint.departamento_cod && complaint.municipio_cod ? 2 : 1,
          documentacion_final: complaint.documentacion_rta_final ? "Sí" : "No",
          expres: complaint.queja_expres === 2 ? "Sí" : "No",
          tutela_nombre:
            complaint.tutela === 1
              ? "La queja o reclamo tiene asociada una acción de tutela"
              : "La queja o reclamo tiene NO asociada una acción de tutela",
          foreign: complaint.departamento_cod === null ? true : false,
          fecha_cierre: complaint.fecha_cierre
            ? format(new Date(complaint.fecha_cierre), "dd/MM/yyyy hh:mm a")
            : "",
          archivos_apoderado: complaint.archivos_apoderado
            ? complaint?.mis_archivos?.filter(
                (item) => item.reference === "attorney"
              )
            : [],
        });
        this.getUserDataAlternative(complaint);
        this.loading = false;
      }
    },
    async getUserData() {
      let identificationType_id = 1; // Por defecto 1
      const objIdentificationType =
        this.$store.state.form.optionsIdentificationType?.filter(
          (identificationType) =>
            identificationType.name == this.userBasicInfo.document_type
        );
      if (objIdentificationType.length > 0) {
        identificationType_id = objIdentificationType[0].id;
      }
      let result = await this.$api.getQueryPerson(
        `?identification_number=${this.userBasicInfo.document_number}&identification_type=${identificationType_id}`
      );
      this.loading = false;

      if (result.error)
        this.showToast(
          "error",
          "No se encontraron datos del usuario de la queja."
        );

      if (result.data) {
        if (result.data.results.length > 0) {
          const user = result.data.results[0];
          this.customer = {
            ...user,
            identification_type: user.identification_type,
            identification_number: user.identification_number,
            birth_date: user.birth_date,
            person_type: user.person_type,
            first_name: user.user_id.first_name,
            last_name: user.user_id.last_name,
            business_name: user.user_id?.business_name,
            phone: user.user_id?.phone,
            email: user.user_id.email,
            identification_type_name: this.userBasicInfo.document_type,
          };
        } else {
          this.customer = null;
        }
      }
    },
    getUserDataAlternative(complaint) {
      if (!this.customer) {
        this.customer = {};
        let { nombres, numero_id_CF, tipo_id_CF, tipo_persona } = complaint;
        this.customer = {
          identification_type_name: tipo_id_CF,
          identification_number: numero_id_CF,
          person_type: tipo_persona === "Natural" ? 1 : 2,
          first_name: nombres,
          last_name: null,
        };
      }
    },
    showToast(result = "info", msg) {
      this.$notification[result]({
        message: "Resultado",
        description: msg,
        onClick: () => {},
      });
    },

    toggleAccordion(idx) {
      for (let item in this.$refs) {
        if (idx != item) this.$refs[item][0].hidden = true;
      }
      this.$refs[idx][0].hidden = !this.$refs[idx][0].hidden;
      this.accordionOpened = this.$refs[idx][0].hidden ? "" : idx;
    },
  },
};
</script>